<template>
  <div class="report px-3">
    <div class="px-3_">
      <b-row align-v="center" class="mt-2 mb-2">
        <b-col lg="8" md="6" sm="6" cols="4" class="text-left mt-2">
          <h4 class="mr-auto d-lg-none">Kế toán</h4>
        </b-col>
        <b-col lg="4" md="6" sm="6" cols="8" class="text-right">
          <date-range
            opens="left"
            :from="from"
            :to="to"
            @date-change="dateChange"
          ></date-range>
        </b-col>
      </b-row>
    </div>
    <div class="d-flex justify-content-center mt-2 mb-2 summary">
      <stats-card
        title="Khách mới"
        type="gradient-orange"
        :sub-title="summary.new ? summary.new.income : '0'"
        class="mr-1 p-1 col-6 col-md-4"
      >
        <template slot="footer">
          <span class="text-nowrap"
            >{{ summary.new ? summary.new.total_user : 0 }} khách,
            {{ summary.new ? summary.new.life_time : 0 }}T</span
          >
          <span class="text-nowrap"
            >(tặng {{ summary.new ? summary.new.bonus : 0 }}T)</span
          >
        </template>
      </stats-card>
      <stats-card
        title="Khách cũ"
        type="gradient-orange"
        :sub-title="summary.old ? summary.old.income : '0'"
        class="ml-1 p-1 col-6 col-md-4"
      >
        <template slot="footer">
          <span class="text-nowrap"
            >{{ summary.old ? summary.old.total_user : 0 }} khách,
            {{ summary.old ? summary.old.life_time : 0 }}T</span
          >
          <span class="text-nowrap"
            >(tặng {{ summary.old ? summary.old.bonus : 0 }}T)</span
          >
        </template>
      </stats-card>
    </div>
    <div class="d-flex flex-column mt-2 mb-2 text-left">
      <sale-card v-if="reportData.length > 0" :sales="reportData"></sale-card>
      <b-alert v-else show variant="secondary" class="text-center"
        >Không có số liệu</b-alert
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "@/components/StatsCard";
import { Utilities } from "@/utilities";
import SaleCard from "@/components/SaleCard.vue";
import moment from "moment";
import DateRange from "@/components/DateRange";

export default {
  data() {
    return {
      reportData: [],
      summary: { old: {}, new: {} },
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD")
    };
  },
  components: {
    StatsCard,
    SaleCard,
    "date-range": DateRange
  },
  methods: {
    async fetchSale() {
      let res = await this.$http.get(
        "report/sale?from=" + this.from + "&to=" + this.to
      );
      if (res.data.code == 0) {
        var data_format = res.data.data.report;

        let summaryFormat = res.data.data.summary
          ? res.data.data.summary
          : null;
        if (summaryFormat && summaryFormat.old && summaryFormat.new) {
          summaryFormat.old.income = Utilities.currencyReport(
            summaryFormat.old.income
          );
          summaryFormat.new.income = Utilities.currencyReport(
            summaryFormat.new.income
          );
        }

        this.reportData = data_format;
        this.summary = summaryFormat;
      }
    },
    async dateChange(date) {
      this.from = date.from;
      this.to = date.to;

      await this.$nextTick();
      if (
        this.$router.history.current.query.from != this.from ||
        this.$router.history.current.query.to != this.to
      ) {
        let r = {
          name: this.$router.history.current.name,
          params: this.$router.history.current.params,
          query: {
            ...this.$router.history.current.query,
            from: this.from,
            to: this.to
          }
        };
        this.$router.replace(r);
      }

      this.fetchSale();
    }
  },
  computed: {
    ...mapGetters(["parseTypes", "game"])
  },
  created() {
    this.from = this.$route.query.from
      ? moment(this.$route.query.from, "YYYY-MM-DD").format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    this.to = this.$route.query.to
      ? moment(this.$route.query.to, "YYYY-MM-DD").format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");

    this.fetchSale();
  }
};
</script>

<style>
.card-footer {
  padding: 0 !important;
}
.card-header {
  padding: 3px !important;
  border-bottom: 1px solid rgb(169 164 164 / 13%);
}
.card-body {
  padding: 1rem !important;
}
.collapse .card {
  border: none;
  background-color: #f7f7f7;
}
.collapse .card .row {
  margin-left: 5px !important;
}
</style>

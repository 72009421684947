var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{class:[
    { 'card-lift--hover': _vm.hover },
    { shadow: _vm.shadow },
    { [`shadow-${_vm.shadowSize}`]: _vm.shadowSize },
    { [`bg-gradient-${_vm.gradient}`]: _vm.gradient },
    { [`bg-${_vm.type}`]: _vm.type }
  ],attrs:{"no-body":""}},[(!_vm.noBody)?_c('b-card-body',{class:_vm.bodyClasses},[_c('b-row',[_c('b-col',[_vm._t("default",function(){return [(_vm.title)?_c('h5',{staticClass:"card-title text-uppercase text-muted mb-0"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.subTitle)?_c('span',{staticClass:"h4 font-weight-bold mb-0"},[_vm._v(_vm._s(_vm.subTitle))]):_vm._e()]})],2),(_vm.$slots.icon || _vm.icon)?_c('b-col',{attrs:{"cols":"auto"}},[_vm._t("icon",function(){return [_c('div',{staticClass:"icon icon-shape text-white rounded-circle shadow",class:[`bg-${_vm.type}`, _vm.iconClasses]},[_c('i',{class:_vm.icon})])]})],2):_vm._e()],1)],1):_vm._e(),(_vm.$slots.footer)?_c('b-card-footer',{class:_vm.footerClasses},[_c('p',{staticClass:"p-3 mb-0 text-sm"},[_vm._t("footer")],2)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
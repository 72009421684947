<template>
  <div>
    <div
      v-for="(sale, idx) in sales"
      :key="idx"
      class="d-flex flex-column text-left"
    >
      <b-card no-body>
        <template #header>
          <b-row :class="'m-0 level-' + sale.index">
            <b-col class="pr-0 pl-1">
              <b-link v-b-toggle="'collapse-' + sale.userName" class="uname">
                <span v-if="!sale.last_extend" class="when-open">
                  <b-icon icon="dash-square" aria-hidden="true"></b-icon>
                </span>
                <span v-if="!sale.last_extend" class="when-closed">
                  <b-icon icon="plus-square" aria-hidden="true"></b-icon>
                </span>
                <b-icon v-else icon="person-fill"></b-icon>
                {{ sale.userName }}
                <span v-if="sale.note">({{ sale.note }})</span>
              </b-link>
            </b-col>
            <b-col class="text-right pr-0 pl-0">
              <span class="text-muted text-nowrap"
                ><span
                  :id="'pay_' + idx + '_' + sale.user"
                  class="text-danger money-info"
                  >{{ formatMoney(sale.pay) }}</span
                >
                |
                <span
                  :id="'income_' + idx + '_' + sale.user"
                  class="text-primary money-info"
                  >{{ formatMoney(sale.income) }}</span
                ></span
              >
              <b-tooltip
                :target="'pay_' + idx + '_' + sale.user"
                triggers="hover"
                >Thanh toán</b-tooltip
              >
              <b-tooltip
                :target="'income_' + idx + '_' + sale.user"
                triggers="hover"
                >Thu về</b-tooltip
              >
            </b-col>
          </b-row>
          <b-row :class="'m-0 level-' + sale.index">
            <b-col v-if="sale.last_extend">
              <small class="pl-1 text-muted">
                Gia hạn cuối: {{ formatDate(sale.last_extend) }}
              </small>
            </b-col>
            <b-col class="text-right pr-0 pl-0">
              <small class="pl-1 text-muted"
                ><span id="pay-lifetime"
                  >Tổng: {{ formatPoint(sale.life_time) }} tháng</span
                >
                <span id="bonus-lifetime"
                  >(+{{ formatPoint(sale.bonus) }} tháng)</span
                ></small
              >
            </b-col>
          </b-row>
        </template>
        <b-collapse :id="'collapse-' + sale.userName" class="">
          <sale-card :sales="sale.children"></sale-card>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import SaleCard from "@/components/SaleCard.vue";
import { Utilities } from "@/utilities";

export default {
  name: "sale-card",
  props: ["sales"],
  components: {
    SaleCard
  },
  methods: {
    formatMoney(money) {
      return Utilities.currencyReport(money);
    },
    formatPoint(point) {
      return Utilities.thousandFormat(point);
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    }
  }
};
</script>
<style>
.money-info {
  border-bottom: 2px dotted;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.uname {
  text-decoration: none !important;
}
.level-1 {
  padding-left: 1rem;
}
.level-2 {
  padding-left: 2rem;
}
.level-3 {
  padding-left: 3rem;
}
.level-4 {
  padding-left: 4rem;
}
.level-5 {
  padding-left: 5rem;
}
.level-6 {
  padding-left: 6rem;
}
.level-7 {
  padding-left: 7rem;
}
.level-8 {
  padding-left: 8rem;
}
.level-9 {
  padding-left: 9rem;
}
.level-10 {
  padding-left: 10rem;
}
</style>

<template>
  <b-card
    no-body
    :class="[
      { 'card-lift--hover': hover },
      { shadow: shadow },
      { [`shadow-${shadowSize}`]: shadowSize },
      { [`bg-gradient-${gradient}`]: gradient },
      { [`bg-${type}`]: type }
    ]"
  >
    <b-card-body :class="bodyClasses" v-if="!noBody">
      <b-row>
        <b-col>
          <slot>
            <h5 class="card-title text-uppercase text-muted mb-0" v-if="title">
              {{ title }}
            </h5>
            <span class="h4 font-weight-bold mb-0" v-if="subTitle">{{
              subTitle
            }}</span>
          </slot>
        </b-col>

        <b-col cols="auto" v-if="$slots.icon || icon">
          <slot name="icon">
            <div
              class="icon icon-shape text-white rounded-circle shadow"
              :class="[`bg-${type}`, iconClasses]"
            >
              <i :class="icon"></i>
            </div>
          </slot>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-footer :class="footerClasses" v-if="$slots.footer">
      <p class="p-3 mb-0 text-sm">
        <slot name="footer"> </slot>
      </p>
    </b-card-footer>
  </b-card>
</template>
<script>
export default {
  name: "stats-card",
  props: {
    type: {
      type: String,
      default: "primary"
    },
    icon: String,
    title: String,
    subTitle: String,
    iconClasses: [String, Array],
    gradient: {
      type: String,
      description: "Card background gradient type (warning,danger etc)"
    },
    hover: {
      type: Boolean,
      description: "Whether card should move on hover"
    },
    shadow: {
      type: Boolean,
      description: "Whether card has shadow"
    },
    shadowSize: {
      type: String,
      description: "Card shadow size"
    },
    noBody: {
      type: Boolean,
      default: false,
      description: "Whether card should have wrapper body class"
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: "Card body css classes"
    },
    headerClasses: {
      type: [String, Object, Array],
      description: "Card header css classes"
    },
    footerClasses: {
      type: [String, Object, Array],
      description: "Card footer css classes"
    }
  }
};
</script>
<style></style>
